import React from "react";
import _ from "lodash";

import { rhythm } from "../utils/typography";
import Img from 'gatsby-image';
import {Link} from 'gatsby';
import { Box } from 'rebass';


const Gallery = ({
    images,
    itemsPerRow: itemsPerRowByBreakpoints = [1],
}) => {
    const aspectRatios = images.map(({ image }) => image.aspectRatio);
    const rowAspectRatioSumsByBreakpoints = _(itemsPerRowByBreakpoints)
        .map(itemsPerRow =>
            _(aspectRatios)
                .chunk(itemsPerRow)
                .map(row => _.sum(row))
                .value()
        )
        .value();

    return (
        <Box>
            {images.map(({ title, image, slug }, i) =>
                <Link key={i} to={slug} css={`
                    box-shadow: none;
                    text-decoration: none;
                    color: inherit;
                    `}>
                    <Box
                        fluid={image}
                        title={title}
                        width={rowAspectRatioSumsByBreakpoints.map(
                            (rowAspectRatioSums, j) => {
                                const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j]);
                                const rowAspectRatioSum = rowAspectRatioSums[rowIndex];
                                const percentage = image.aspectRatio / rowAspectRatioSum;
                                return `${percentage * 100}%`;
                            },
                        )}
                        css={`
                        display: inline-block;
                        vertical-align: middle;
                        transition: filter 0.3s;
                        :hover {
                            filter: brightness(87.5%);
                        }
                        `
                        }
                    >
                        <Img
                            fluid={image}
                            title={title}
                            css={`
                                margin: ${rhythm(0.2)}
                            `}
                            imgStyle={{marginBottom: 0}}
                        />
                    </Box>
                </Link>

            )}
        </Box>
    );
}

export default Gallery

