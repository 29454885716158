import React from "react";
import _ from "lodash";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Gallery from "../components/Gallery";

class FilterGeneralIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    
    const images = _(posts)
                  .map(({node}) => (
                    {
                      title: node.frontmatter.title, 
                      image: node.frontmatter.cover.childImageSharp.fluid,
                      slug: node.fields.slug
                    }))
                  .value()

    return (
      <Layout location={this.props.location} title={siteTitle} displayNav>
        <SEO
          title="All posts"
          keywords={[`blog`, `sơn tăng`, `chuyện của Sơn`, `chuyen cua Son`, `son tang`]}
        />
        <div style={{flex: 1}}>
            <Gallery images={images} itemsPerRow={[1, 1, 2, 3]}/>
        </div>
      </Layout>
    )
  }
}

export default FilterGeneralIndex